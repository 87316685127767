<script>
import PageHeader from "@/components/page-header";
import { postPrograms } from "@/services/api/tribe";
import { uplodDetailsImage } from "@/services/api/tribe";
import { getTribes } from "@/services/api/tribe";
import { getUserType } from "@/services/setUserType";
import Layout from "../../layouts/main";

import Vue from "vue";
import VueFormulate from "@braid/vue-formulate";

import moment from "moment";
Vue.use(VueFormulate);
export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      type: "",
      multi: true,
      dg: "",
      proDate: [],
      listOfTribes: [],
      selectFile: null,
      detailsFile: null,
      name: "",
      description: "",
      tribe_id: "",
      price: "",
      available_dates: [],
      cancellation_policy: "",
      min_people: "",
      max_people: "",
      included: "",
      excluded: "",
      dates: {
        date: "",
      },
      programDetailList: {
        title: "",
        description: "",
        image: "",
      },
      isSubmitting: false,
      programData: {
        date: [],
        programDetailList: [],
      },
      //this the title
      title: "Add Program",
      items: [
        {
          text: "Programs",
        },
        {
          text: "Add Program",
          active: true,
        },
      ],
    };
  },
  mounted() {
    this.getTribesList();
    this.checkUserType();
  },
  methods: {
    checkUserType() {
      this.type = getUserType();
    },
    formatDate(date) {
      let formatedVal = date.toLocaleDateString();
      let finalDate = moment(String(formatedVal)).format("YYYY-MM-DD");
      if (!this.proDate.includes(finalDate)) {
        this.proDate.push(finalDate);
      }
      console.log(this.proDate);
      return finalDate;
    },
    getTribesList() {
      let self = this;
      getTribes()
        .then((res) => {
          self.listOfTribes = res;
          this.listOfTribes = res.data.tribes;
          console.log(this.listOfTribes);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onFileSelected(event) {
      this.selectFile = event.target.files[0];
      console.log(this.selectFile);
      console.log("PRINT EVENT");
    },
    onChange() {
      console.log("Date Selected");
    },

    onDetailsSelected(event) {
      this.detailsFile = event.target.files[0];
    },
    async addPrograms() {
      const fb = new FormData();
      console.log(this.dates);
      fb.append("file", this.selectFile);
      fb.append("name", this.name);
      fb.append("vendor_id", localStorage.getItem("currentLoggedId"));
      fb.append("description", this.description);
      fb.append("tribe_id", this.tribe_id);
      fb.append("type", this.type);
      fb.append("price[adults]", this.price);
      fb.append("cancellation_policy", this.cancellation_policy);
      this.available_dates.forEach((e) => {
        if (e != "") fb.append("available_dates[]", e);
      });
      fb.append("more_details[min_people]", this.min_people);
      fb.append("more_details[max_people]", this.max_people);
      fb.append("more_details[included]", this.included);
      fb.append("more_details[excluded]", this.excluded);
      fb.append("details[title]", this.programDetailList.title);
      fb.append("details[description]", this.programDetailList.description);
      fb.append("details[image]", this.programDetailList.image);
      postPrograms(fb).then((result) => {
        console.warn(result);
      });

      alert("Record has been added");
      this.goToAppProgramms();
    },
    goToAppProgramms() {
      this.$router.push("programsList");
    },

    AddingAvailableDate() {
      this.isSubmitting = true;
      this.available_dates.push(this.dates.date);
      console.log(this.available_dates);
    },

    deleteDate(id) {
      this.available_dates.splice(id, 1);
    },
    deleteDetail(id) {
      this.programData.programDetailList.splice(id, 1);
    },
    submitProgramDetails() {
      this.isSubmitting = true;
      const fb1 = new FormData();
      fb1.append("file", this.detailsFile);
      fb1.append("type", this.type);
      uplodDetailsImage(fb1).then((result) => {
        console.warn(result);
        this.programDetailList.image = result.data.link;
        this.programData.programDetailList.push({
          title: this.programDetailList.title,
          description: this.programDetailList.description,
          image: this.programDetailList.image,
        });
      });
    },
  },
};
</script>  
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <form form @submit.prevent="addProgramms">
                  <div style="padding: 7px; border: 1px ridge; height: 630px">
                    <br />
                    <div class="col-12">
                      <b-form-group label="Name" label-for="text">
                        <b-form-input
                          type="text"
                          :name="name"
                          v-model="name"
                          placeholder="Name"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-12">
                      <b-form-group
                        id="example text"
                        label="Description"
                        label-for="text"
                      >
                        <b-form-input
                          type="text"
                          :name="description"
                          placeholder="description"
                          v-model="description"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-12">
                      <FormulateInput
                        type="file"
                        @change="onFileSelected"
                        label="Select an image to upload :"
                        validation="mime:image/jpeg,image/png,image/gif"
                      />
                    </div>
                    <br />
                    <div class="container-fluid">
                      <div class="row">
                        <div class="col-sm-3">
                          <b-form-group label="Available Dates">
                            <b-form-input
                              type="date"
                              name="date"
                              @change="AddingAvailableDate()"
                              placeholder="Select Dates"
                              v-model="dates.date"
                            ></b-form-input>
                          </b-form-group>
                        </div>
                        <div class="col-sm-3">
                          <b-form-group label="Price">
                            <b-form-input
                              type="number"
                              :name="price"
                              for="text"
                              value="price"
                              placeholder="133"
                              v-model="price"
                            ></b-form-input>
                          </b-form-group>
                        </div>
                        <div class="col-sm-3">
                          <b-form-group
                            id="example text"
                            label="Tribe"
                            label-for="text"
                          >
                            <b-form-select type="text" v-model="tribe_id">
                              <b-form-select-option
                                type="text"
                                v-for="tribe in listOfTribes"
                                :key="tribe._id"
                                :value="tribe._id"
                              >
                                {{ tribe.name }}
                              </b-form-select-option>
                            </b-form-select>
                          </b-form-group>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div class="container-fluid">
                      <div class="row">
                        <div class="col-sm-3">
                          <table
                            style="
                              overflow-y: scroll;
                              display: block;
                              height: 120px;
                            "
                            class="col-12"
                          >
                            <tr v-if="available_dates != []"></tr>
                            <th class="col-12;">Dates</th>
                            <th class="text-danger">Delete</th>
                            <tr
                              v-for="(item, index) in available_dates"
                              :key="index"
                            >
                              <td class="col-12">
                                {{ item }}
                              </td>
                              <td>
                                <a
                                  href="javascript:void(0);"
                                  class="text-danger"
                                  v-b-tooltip.hover
                                  title="Delete"
                                  @click="deleteDate(id)"
                                >
                                  <i class="mdi mdi-trash-can font-size-18"></i>
                                </a>
                              </td>
                            </tr>
                          </table>
                        </div>
                        <div class="col-sm-3">
                          <b-form-group
                            label="Minimum People"
                            label-for="min_people"
                          >
                            <b-form-input
                              type="number"
                              for="number"
                              :name="min_people"
                              placeholder="3"
                              v-model="min_people"
                            ></b-form-input>
                          </b-form-group>
                        </div>
                        <div class="col-sm-3">
                          <b-form-group
                            id="example-number"
                            label="Maximum People"
                            label-for="max_people"
                          >
                            <b-form-input
                              type="number"
                              for="number"
                              :name="max_people"
                              placeholder="13"
                              v-model="max_people"
                            ></b-form-input>
                          </b-form-group>
                        </div>
                      </div>
                    </div>
                    <br />
                    <br />
                  </div>
                  <br />
                  <br />
                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-12">
                        <h6
                          class="col-sm-2"
                          style="
                            position: relative;
                            left: 0%;
                            font-weight: bold;
                          "
                        >
                          POLICIES
                        </h6>
                        <div
                          class="col-12"
                          style="
                    height: 300px
                    margin: 0px auto;
                    padding: 15px;
                    border: 1px ridge;
                  "
                        >
                          <div class="col-12">
                            <b-form-group
                              label="Cancellation Policy"
                              label-for="text"
                            >
                              <b-form-input
                                type="text"
                                for="cancellation_policy"
                                placeholder="PLC"
                                :name="cancellation_policy"
                                v-model="cancellation_policy"
                              ></b-form-input>
                            </b-form-group>
                          </div>
                          <div class="col-12">
                            <b-form-group
                              id="example text"
                              label="Included"
                              label-for="text"
                            >
                              <b-form-input
                                type="text"
                                for="text"
                                placeholder="INC"
                                :name="included"
                                v-model="included"
                              ></b-form-input>
                            </b-form-group>
                          </div>
                          <div class="col-12">
                            <b-form-group
                              id="example text"
                              label="Excluded"
                              label-for="text"
                            >
                              <b-form-input
                                type="text"
                                for="text"
                                :name="excluded"
                                placeholder="NOT INC"
                                v-model="excluded"
                              ></b-form-input>
                            </b-form-group>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                </form>
                <br />
                <br />
                <h6 style="font-weight: bold">DETAILS</h6>
                <form
                  @submit.prevent="submitProgramDetails"
                  style="
                    height: 475px
                    margin: 0px auto;
                    padding: 15px;
                    border: 1px ridge;
                  "
                >
                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-6">
                        <table
                          class="col-12"
                          style="
                            overflow-y: scroll;
                            height: 150px;
                            display: block;
                          "
                        >
                          <thead>
                            <tr v-if="programData.programDetailList != []">
                              <th class="col-4">Title</th>
                              <th class="col-4">Description</th>
                              <th class="col-4">Image</th>

                              <th class="text-danger">Delete</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(
                                item, index
                              ) in programData.programDetailList"
                              :key="index"
                            >
                              <td>{{ item.title }}</td>
                              <td>{{ item.description }}</td>
                              <td>
                                <img
                                  :src="item.image"
                                  height="50;"
                                  width="60"
                                />
                              </td>
                              <td>
                                <a
                                  href="javascript:void(0);"
                                  class="text-danger"
                                  v-b-tooltip.hover
                                  title="Delete"
                                  @click="deleteDetail(id)"
                                >
                                  <i class="mdi mdi-trash-can font-size-18"></i>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                  <br />
                  <div class="col-6">
                    <b-form-input
                      type="text"
                      :name="title"
                      v-model="programDetailList.title"
                      placeholder="Title"
                      ref="title"
                    ></b-form-input>
                  </div>
                  <div class="col-6">
                    <b-form-input
                      type="text"
                      name="description"
                      v-model="programDetailList.description"
                      placeholder="Description"
                      ref="description"
                    ></b-form-input>
                  </div>
                  <div class="col-6">
                    <FormulateInput
                      type="file"
                      @change="onDetailsSelected"
                      label="Select an image to upload :"
                      validation="mime:image/jpeg,image/png,image/gif"
                    />
                  </div>
                  <br />

                  <div class="container-fluid">
                    <b-button style="padding: 5px 39px" type="submit"
                      >Add</b-button
                    >
                  </div>
                </form>
                <br />
                <!--END OF DETAILS FORM-->
                <div class="text-right">
                  <a
                    class="btn btn-success mb-2"
                    style="padding: 10px 32px"
                    @click="addPrograms()"
                    >Save Program</a
                  >
                  <b-button
                    class="mb-2"
                    variant="danger"
                    style="padding: 10px 20px"
                    @click="goToAppProgramms()"
                    >Cancel</b-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>                                                                   <style>
.dropdown {
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
.dropdown-input {
  width: 100%;
  padding: 10px 16px;
  border: 1px solid transparent;
  background: #edf2f7;
  line-height: 1.5em;
  outline: none;
  border-radius: 8px;
}
.dropdown-input:focus {
  background: #fff;
  border-color: #e2e8f0;
}
.dropdown-input::placeholder {
  opacity: 0.7;
}

table th {
  border: 1px solid black;
  border-collapse: collapse;
}
table td {
  border-collapse: collapse;
  border: 1px solid black;
}
table td:last-child {
  border-right: none;
}

.Container button {
  /* You Can Name it what you want*/
  margin-right: 16px;
}
.Container button {
  /* You Can Name it what you want*/
  margin-right: 16px;
}

.text-right button {
  /* You Can Name it what you want*/
  margin-left: 10px;
}
</style>           
                                                                  